<template>
  <el-main>
    <page-title show-back-btn>
      <template slot="btn-inner">
        <debounce-button v-if="viewEdit()" v-loading="loading.submit" type="primary" @click="submitForms">
          提交
        </debounce-button>
      </template>
    </page-title>
    <div class="partition-area">
      <div v-loading="loading.detail" class="partition-area">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form ref="appForm" :model="appForm" :rules="rules" label-width="100px" :label-suffix="constants.labelSuffix">
              <ics-page-inner title="合同信息" :gutter-number="20">
                <el-col :span="24">
                  <el-form-item label="模板文件">
                    <p v-if="viewEdit()">
                      <el-button type="primary" @click="clickFiles">
                        上传合同附件
                      </el-button>
                    </p>
                    <p>
                      <el-link v-if="appForm.contractUrlName" type="primary" class="text-btn" :underline="false" @click="utils.downloadP('concat', appForm.contractUrl)">
                        {{ utils.isEffectiveCommon(appForm.contractUrlName) }}
                      </el-link>
                    </p>
                  </el-form-item>
                </el-col>
                <el-col :span="12" label-position="top">
                  <el-form-item prop="contractName" label="模板名称">
                    <ics-item-inner :prop="appForm.contractName" :format="utils.isEffectiveCommon">
                      <el-input v-model="appForm.contractName" placeholder="请输入模板名称" />
                    </ics-item-inner>
                  </el-form-item>
                </el-col>
                <el-col v-if="viewEdit()" :span="12">
                  <el-form-item prop="capCode" label="所属资金方">
                    <ics-item-inner :prop="appForm.capCode" :format="utils.isEffectiveCommon">
                      <el-select v-model="appForm.capCode" placeholder="请选择所属资金方" filterable @change="(val)=>companyChange(val)">
                        <el-option v-for="item in capList" :key="item.capCode" :label="`${item.capName}（${item.capCode}）`" :value="item.capCode" />
                      </el-select>
                    </ics-item-inner>
                  </el-form-item>
                </el-col>
                <el-col v-if="!viewEdit()" :span="12">
                  <el-form-item label="所属资金方">
                    <ics-item-inner :prop="appForm.capName" :format="utils.isEffectiveCommon" />
                  </el-form-item>
                </el-col>
                <el-col :span="24" style="margin-top: 5px;">
                  <ics-page-inner title="签署方">
                    <template v-if="viewEdit() && $route.params.editMode === 'add'" slot="btn-inner">
                      <div class="btn-inner">
                        <el-button :disabled="addSignInfoButtonDisabled" type="primary" @click="addContractSign">
                          新增签署方
                        </el-button>
                      </div>
                    </template>
                    <el-form-item prop="signInfoList" label="" label-width="40px">
                      <el-table :data="appForm.signInfoList" style="margin-left: -20px;" class="table-input">
                        <el-table-column label="签署方" show-overflow-tooltip width="80">
                          <template slot-scope="scope">
                            <el-form-item>
                              <span>{{ scope.row.signatory }}</span>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column label="签署方性质">
                          <template slot-scope="scope">
                            <el-form-item v-if="viewEdit() && $route.params.editMode === 'add'" label-width="0" :prop="`signInfoList[${scope.$index}].natureSignatory`" :rules="rules.natureSignatory">
                              <el-select v-model="scope.row.natureSignatory" filterable @change="value => changeNatureSignatory(value, scope.$index)">
                                <el-option v-for="item in _.filter(constants.companyType,{states:1})" :key="item.value" :label="item.label" :value="item.value" />
                              </el-select>
                              <template slot="error" slot-scope="errScope">
                                <el-tooltip effect="dark" :content="errScope.error" placement="top-start">
                                  <span class="error-icon danger"><i class="el-icon-warning" /></span>
                                </el-tooltip>
                              </template>
                            </el-form-item>
                            <el-form-item v-if="$route.params.editMode !== 'add'">
                              <span>{{ utils.statusFormat(String(scope.row.natureSignatory), 'companyType') }}</span>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column label="签章字样">
                          <template slot-scope="scope">
                            <!-- <span>{{ signatoryFormat(scope.$index) }}</span> -->
                            <el-form-item v-if="viewEdit() && $route.params.editMode === 'add'" label-width="0" :prop="`signInfoList[${scope.$index}].signWordModel`" :rules="rules.signWordModel">
                              <el-input v-model="scope.row.signWordModel" placeholder="请输入签章字样" />
                            </el-form-item>
                            <el-form-item v-if="$route.params.editMode !== 'add'">
                              <span>{{ utils.isEffectiveCommon(scope.row.signWordModel) }}</span>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column v-if="$route.params.editMode === 'add'" label="操作" width="100px">
                          <template slot-scope="scope">
                            <a v-if="scope.$index > 0" class="text-btn danger" href="javascript:" @click="delSign(scope.$index)">删除</a>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-form-item>
                  </ics-page-inner>
                </el-col>
                <el-col :span="24">
                  <ics-page-inner title="变量">
                    <el-form>
                      <el-col :span="24">
                        <el-form-item label="选择变量：">
                          <el-input v-model="searchForm.code" placeholder="请输入要搜索的变量编码" style="width: 250px;" @change="changeCode" />
                          <div v-show="false">
                            <p><el-input /></p>
                          </div>
                        </el-form-item>
                      </el-col>
                    </el-form>
                  </ics-page-inner>
                  <ics-page-inner title="变量" :show-header="true" style="margin-top: -30px;">
                    <div class="partition-area">
                      <el-table v-loading="loading.list" :data="variableList" class="mb20" max-height="220px">
                        <el-table-column v-if="viewEdit()" label="变量编码" min-width="100" show-overflow-tooltip>
                          <template slot-scope="scope">
                            <div draggable="true" @dragstart="drag($event)">
                              <a href="javascript:;">{{ scope.row.variable }}</a>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column v-if="!viewEdit()" prop="variable" label="变量编码" min-width="100" show-overflow-tooltip />
                        <el-table-column prop="variableName" label="名称" min-width="130" show-overflow-tooltip />
                        <el-table-column prop="remark" label="描述" min-width="130" show-overflow-tooltip />
                      </el-table>
                      <p style="margin-left: 10px;color: red">
                        注：可将变量编码拖至下方富文本编辑器中
                      </p>
                    </div>
                  </ics-page-inner>
                </el-col>
                <el-col :span="24">
                  <ics-page-inner title="模板内容">
                    <template slot="btn-inner">
                      <div class="btn-inner">
                        <el-button type="success" @click="viewerPdf">
                          预览模板内容
                        </el-button>
                      </div>
                    </template>
                    <div class="editor-content">
                      <el-form-item prop="contractContent" label="">
                        <div class="editor-container">
                          <textarea id="editor" v-model="appForm.contractContent" />
                        </div>
                      </el-form-item>
                    </div>
                  </ics-page-inner>
                </el-col>
              </ics-page-inner>
            </el-form>
          </el-col>
        </el-row>
      </div>
    </div>
    <ics-upload-inner :upload-dialog="dialog.upload" @onSuccessFile="onSuccessFile" />
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsUploadInner from './components/upload-inner'
import CKEDITOR from 'CKEDITOR'
import utils from "../../../assets/js/utils";
export default {
  components: { IcsUploadInner },
  mixins: [routeEnterMixin],
  data () {
    return {
      id: this.$route.query.id,
      appForm: {
        contractName: '',
        contractUrl: '',
        contractUrlName: '',
        capCode: '',
        capName: '',
        contractContent: '',
        // 签署方list
        signInfoList: [
          {
            signatory: '',
            natureSignatory: '',
            signWordModel: ''
          }
        ]
      },
      addSignInfoButtonDisabled: false,
      searchForm: {
        code: ''
      },
      dialog: {
        upload: {
          visible: false,
          title: '上传合同模板文件'
        }
      },
      variableList: [],
      capList: [],
      dragVal: '',
      // 富文本编辑器默认内容
      content: '<h2>请输入内容..</h2>',
      // 富文本编辑器配置
      editorOption: {
        // Some Quill options...
      },
      rules: {
        contractName: this.changeRules({ name: '合同名称', required: true }),
        contractUrl: [
          { required: true, message: '请上传合同附件', trigger: 'change' }
        ],
        capCode: [
          { required: true, message: '请选择所属资金方', trigger: 'change' }
        ],
        natureSignatory: [
          { required: true, message: '请选择签署方性质', trigger: 'change' }
        ],
        contractContent: [
          { required: true, message: '请输入模板内容', trigger: 'change' }
        ],
      }
    }
  },
  created () {
    this.getTemplateCodeList({})
    if (this.viewEdit()) {
      this.getCapCompanyList()
      this.setSignatoryAndSignWordModel()
    }
    setTimeout(() => {
      this.editorCkeditor()
    }, 100)
  },
  methods: {
    editorCkeditor () {
      this.editor = CKEDITOR.replace('editor', {
        removeButtons: 'Maximize,Source,Templates,About,CreateDiv,Language,BidiRtl,BidiLtr,Flash,Smiley,SpecialChar,Iframe,PageBreak,HiddenField,ImageButton,Button,Textarea,TextField,Select,Radio,Checkbox,SelectAll,Save,NewPage,ExportPdf,Preview,Print,Blockquote,ShowBlocks',
        image_previewText: ' ',
        isReadOnly: true,
        bodyClass: 'document-editor',
        filebrowserImageUploadUrl: this.filebrowserImageUploadUrl,
        width: 940,
        height: 420
      })
      CKEDITOR.addCss(
        'body.document-editor { margin: 0.5cm auto; border: 1px #D3D3D3 solid; border-radius: 5px; background: white; box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }' +
        'body.document-editor, div.cke_editable { width: 570px; padding: 1cm 2cm 2cm; } ' +
        'body.document-editor table td > p, div.cke_editable table td > p { margin-top: 0; margin-bottom: 0; padding: 4px 0 3px 5px;} ' +
        'blockquote { font-family: sans-serif, Arial, Verdana, "Trebuchet MS", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; } ');
      CKEDITOR.on('instanceReady', (e) => {
        if (this.id) {
          this.getDetail()
        }
        if (!this.viewEdit()) {
          this.editor.setReadOnly(true)
        }
      })
    },
    getDetail () {
      this.loading.detail = true
      this.api.contract.template.contractDetails(this.id).then(result => {
        const data = result.data.data
        this.appForm = data.template
        this.editor.setData(this.appForm.contractContent)
        this.appForm.signInfoList = data.sign
      }).finally(() => {
        this.loading.detail = false
      })
    },
    getTemplateCodeList (searchForm) {
      this.loading.list = true
      this.api.contract.template.getTemplateCodeList(searchForm).then(result => {
        this.variableList = result.data.data || []
      }).finally(() => {
        this.loading.list = false
      })
    },
    changeCode () {
      const searchForm = {
        code: this.searchForm.code
      }
      this.getTemplateCodeList(searchForm)
    },
    getCapCompanyList () {
      this.api.company.capital.getCapList().then(result => {
        this.capList = result.data.data || []
      }).finally(() => {
      })
    },
    companyChange (val) {
      const data = this._.find(this.capList, { capCode: val }) || {}
      const { capName } = data
      this.appForm = this._.assign(this.appForm, { capName })
    },
    drag (ev) {
      this.dragVal = ev.currentTarget.cloneNode(true)
      console.log(ev)
    },
    // 允许放下拖拽
    allowDrop(ev) {
      ev.preventDefault();
      console.log(ev)
    },
    // 放下事件
    drop(ev) {
      debugger
      ev.preventDefault()//阻止浏览器读取文件
      ev.preventDefault();
      let treeNode = ev.target;
      if (treeNode) {
        treeNode.appendChild(this.dragVal);
      }
    },
    clickFiles () {
      this.dialog.upload.visible = true
    },
    onSuccessFile (response) {
      const data = this._.cloneDeep(response.data)
      data.contractUrl = data.path
      data.contractUrlName = data.name
      const {contractUrl, contractUrlName} = data
      this.appForm = this._.assign(this.appForm, {contractUrl, contractUrlName})
    },
    // 签署方信息信息新增按钮
    addContractSign () {
      if (this.appForm.signInfoList.length >= 4) {
        this.addSignInfoButtonDisabled = true
        this.$message.error('合同签署方最多四方')
        return false
      }
      const contractSignInfo = {
        signatory: '',
        natureSignatory: '',
        signWordModel: ''
      }
      //
      this.$set(this.appForm.signInfoList, this.appForm.signInfoList.length, contractSignInfo)
      // 设置签署方信息
      this.setSignatoryAndSignWordModel()
    },
    // 签署方
    signatoryFormat (index) {
      const typeObj = {
        0: '甲方',
        1: '乙方',
        2: '丙方',
        3: '丁方'
      }
      return typeObj[index] || '-'
    },
    // 设置签署方信息
    setSignatoryAndSignWordModel () {
      this.appForm.signInfoList.forEach((item, index) => {
        item.signatory = this.signatoryFormat(index)
        item.signWordModel = this.signatoryFormat(index) + '(签章)'
      })
    },
    // 签署方信息信息删除按钮
    delSign (index) {
      this.appForm.signInfoList.splice(index, 1)
      this.addSignInfoButtonDisabled = (this.appForm.signInfoList.length >= 4)
      // 设置签署方信息
      this.setSignatoryAndSignWordModel()
    },
    viewerPdf () {
      const contractContent = this.editor.getData()
      if (!contractContent) {
        this.$message({
          message: '请输入模板内容',
          type: 'warning'
        })
        return false
      }
      const data = {
        contractContent: contractContent
      }
      this.api.contract.template.preview(data).then(result => {
        let binaryData = []
        binaryData.push(result.data)
        console.log(binaryData);
        // 记得一定要设置application的类型
        let url = window.URL.createObjectURL(
          new Blob(binaryData, {
            type: "application/pdf;charset=utf-8",
          })
        )
        if(url){
          if (utils.getExplorer() === 'ie') {
            this.$message({
              message: '请切换置谷歌浏览器预览',
              type: 'warning'
            })
            return false
          } else {
            window.open(url,'_blank')
          }
        }
      })
    },
    submitForms () {
      const contractContent = this.editor.getData()
      this.appForm.contractContent = contractContent
      this.$refs.appForm.validate().then(() => {
        const data = this._.cloneDeep(this.appForm)
        this.loading.submit = true
        const info = {
          template: {
            contractName: data.contractName,
            contractUrl: data.contractUrl,
            contractUrlName: data.contractUrlName,
            capCode: data.capCode,
            capName: data.capName,
            contractContent: data.contractContent
          },
          sign: data.signInfoList
        }
        if (this.id) {
          info.template.id = this.id
          info.template.contractTemplateNumber = data.contractTemplateNumber
          this.api.contract.template.updateTemplate(info).then(result => {
            if (result.data.success === true) {
              this.loading.submit = false
              this.$message.success('操作成功')
              this.$router.back()
            } else {
              this.$message.error(result.data.message)
            }
          }).catch(e => {
            this.loading.submit = false
          })
        } else {
          this.api.contract.template.addTemplate(info).then(result => {
            if (result.data.success === true) {
              this.loading.submit = false
              this.$message.success('操作成功')
              this.$router.back()
            } else {
              this.$message.error(result.data.message)
            }
          }).catch(e => {
            this.loading.submit = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
